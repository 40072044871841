import ThemeHome from './page/Theme/Home';
import './App.css';

function App() {
  return (
    <ThemeHome />
  );
}

export default App;
