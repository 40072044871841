import {useState} from 'react';

import {Navbar, Nav,Container} from 'react-bootstrap';


const ThemeHome = () => {


  const [ dataSubmit, setDataSubmit] = useState({
    name_message:"",
    email_message:"",
    message:"",
  })
  
  const [enableButton,setenableButton] = useState (false)

  const handleName = (e) =>{
    e.preventDefault();
    setDataSubmit(prevState => ({...dataSubmit, name_message:e.target.value}));
    if (dataSubmit.name_message !=='' && dataSubmit.email_message !=='' && dataSubmit.message !==''){
      setenableButton(true)
    }
  }

  const handleEmail = (e) =>{
    e.preventDefault();
    setDataSubmit(prevState => ({...dataSubmit, email_message:e.target.value}));
    if (dataSubmit.name_message !=='' && dataSubmit.email_message !=='' && dataSubmit.message !==''){
      setenableButton(true)
    }
  }

  const handleMessage = (e) =>{
    e.preventDefault();
    setDataSubmit(prevState => ({...dataSubmit, message:e.target.value}));
    if (dataSubmit.name_message !=='' && dataSubmit.email_message !=='' && dataSubmit.message !==''){
      setenableButton(true)
    }
  }








  return(
    <>
    <div class="fixed-top">
      <Navbar bg="dark" variant="dark" >
          <Container>
          <Navbar.Brand href="#home">3Top</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#about">Sobre</Nav.Link>
            <Nav.Link href="#projects">Serviços</Nav.Link>
            <Nav.Link href="#signup">Contatos</Nav.Link>
          </Nav>
          </Container>
        </Navbar>
      </div>
       
      
    
      <header class="masthead" id="home">
          <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <h1 class="mx-auto my-0 text-uppercase">3TOP Service</h1>
                <h2 class="text-white-50 mx-auto mt-2 mb-5">SOLUÇÃO DEFINITIVA QUE VOCÊ E SUA EMPRESA PROCURAM.</h2>
                <a class="btn btn-primary" href="#about">Sobre</a>
              </div>
            </div>
          </div>
        </header><section class="about-section text-center" id="about">
          <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
              <div class="col-lg-8">
                <h2 class="text-white mb-4">Equipe</h2>
                <p class="text-white-50">
                Com uma equipe de alto desempenho, procuramos deixar uma estrutura de TI organizada e com a máxima performance. A 3TOPService tem o compromisso de analisar a sua necessidade e possuímos capacidades para trabalhar na construção da solução ideal para o negócio da empresa. Seja um problema ou nova implementação, nós somos capazes de auxiliar em todas as etapas até a conclusão do projeto, com responsabilidade e transparência visando estar adequado a especialidade da empresa.
                <br /><br />
                <h2 class="text-white mb-4">Engenharia de redes </h2>
                A 3TS apresenta o conhecimento e qualidade técnica para analisar, projetar, implementar e aperfeiçoar toda  infra-estrutura de redes de seu ambiente de trabalho e/ou familiar. Tudo isso é possível com a experiência adquirida pelos anos de TI e formação dos membros da equipe.

                <br /><br />
                <h2 class="text-white mb-4">Suporte e Monitoramento </h2>
                A empresa 3TS realiza um monitoramento 24/7 e presta suporte presencial e/ou remoto a todos os níveis técnicos, desde o cliente residencial até técnico avançado para seu ambiente. Nossa expertise em gerência de redes nos permite trabalhar com ferramentas para relatórios e monitoramento deforma pró ativamente.


                <br /><br />
                <h2 class="text-white mb-4">Projetos </h2>
                Em nossos serviços, apresentamos monitoramento detalhado dos projetos de acordo com as necessidades dos clientes, realizamos todo o planejamento, implementação, configuração/execução das instalações, avaliação de funcionamento e entrega do serviço funcional. Executamos um projeto com todos os esforços, para entregar um serviço e trazer resultado para o cliente.
                </p>

                
                
              </div>
            </div>
          </div>
        </section><section class="projects-section bg-light" id="projects">
          <div class="container px-4 px-lg-5">





            <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
              <div class="col-lg-6"><img class="img-fluid" src="assets/img/demo-image-01.jpg" alt="..." /></div>
              <div class="col-lg-6">
                <div class="bg-black text-center h-100 project">
                  <div class="d-flex h-100">
                    <div class="project-text w-100 my-auto text-center text-lg-left">
                      <h4 class="text-white">Serviços Configurações</h4>
                      <p class="mb-0 text-white-50">
                      - Configuração de roteadores BGP;<br />
                      - Configuração de protocolos OSPF,MPLS,EVPN, PPPOE e IPOE entre outros;<br />
                      - Monitoramento NOC 24/7;<br />
                      - Configuração de firewall;<br />
                      - Configuração de Switches (Provedores e empresa);<br />
                      - Implementação de CFTV;<br />
                      - Ambiente de WIFI.<br />
                      </p>
                      <hr class="d-none d-lg-block mb-0 ms-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>





            <div class="row gx-0 justify-content-center">
              <div class="col-lg-6"><img class="img-fluid" src="assets/img/demo-image-02.jpg" alt="..." /></div>
              <div class="col-lg-6 order-lg-first">
                <div class="bg-black text-center h-100 project">
                  <div class="d-flex h-100">
                    <div class="project-text w-100 my-auto text-center text-lg-right">
                      <h4 class="text-white">Projetos</h4>
                      <p class="mb-0 text-white-50">
                      - Implementação logica e física de GPON;<br />
                      - Implementação proxy ou server zabbix;<br />
                      - Consultoria e mapeamento de rede;<br />
                      - Implementação de polícia de segurança de dados;<br />
                      - Avaliação de performance de rede;<br />
                      - Consultoria a níveis N1, N2 e N3.<br />
                      </p>
                      <hr class="d-none d-lg-block mb-0 me-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section><section class="signup-section" id="signup">
          <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5">
              <div class="col-md-10 col-lg-8 mx-auto text-center">
                <i class="far fa-paper-plane fa-2x mb-2 text-white"></i>
                <h2 class="text-white mb-5">Contate-nos ::</h2>

                <form >
                  <div className="mb-3 pt-0">
                    <input
                      type="text"
                      placeholder="Seu Nome"
                      name="name"
                      className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                      onChange={(e) => handleName(e)}
                      value={dataSubmit.name_message}
                      required
                    />
                  </div>
                  <div className="mb-3 pt-0">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                      onChange={(e) => handleEmail(e)}
                      value={dataSubmit.email_message}
                      required
                    />
                  </div>
                  
                  <div className="mb-3 pt-0">
                    <textarea
                      placeholder="Sua Solicitação"
                      name="message"
                      className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                      onChange={(e) => handleMessage(e)}
                      value={dataSubmit.message}
                    />
                  </div>
                  <div className="mb-3 pt-0">
                    
                  <a 
                    class="btn btn-primary enable"
                    href={enableButton === true && `https://api.whatsapp.com/send?phone=5555999692548&text=Nome: ${dataSubmit.name_message} E-mail: ${dataSubmit.email_message} Messagem: ${dataSubmit.message}`}
                    target="_blank" rel="noopener noreferrer"> Informe-me (Preencha os Campos)
                  </a>
                  <br /><br /><br /><br />

                  </div>
                </form>
              </div>
            </div>
          </div>
        
          <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5">
              <div class="col-md-4 mb-3 mb-md-0">
                <div class="card py-4 h-100">
                <div class="card-body text-center">
                    {/* <i class="fas fa-map-marked-alt text-primary mb-2"></i> */}
                    <h4 class="text-uppercase m-0">Equipe </h4>
                    <hr class="my-4 mx-auto" />
                    <div class="small text-black-50">
                      A 3TopService conta com profissionais com mais de 17 anos de experiência na área de tecnológica da informação, contamos com qualificação acadêmica em Ciências da computação e Engenharia elétrica. </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-3 mb-md-0">
                <div class="card py-4 h-100">
                  <div class="card-body text-center">
                    <i class="fas fa-envelope text-primary mb-2"></i>
                    <h4 class="text-uppercase m-0">Email</h4>
                    <hr class="my-4 mx-auto" />
                    <div class="small text-black-50"><a href="mailto:3topservice@gmail.com"> 3topservice@gmail.com</a></div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-3 mb-md-0">
                <div class="card py-4 h-100">
                  <div class="card-body text-center">
                    <h4 class="text-uppercase m-0">Equipe</h4>
                    <hr class="my-4 mx-auto" />
                    <div class="small text-black-50">
                    A Equipe conta com certificações no mais conceituados equipamentos de redes, assim como acompanhamentos em novas tendências tecnológicas e estudos e pesquisa constante em TI.
                    </div>
                    
                    {/*<div class="small text-black-50">
                       <a href="https://www.linkedin.com/in/pedro-eduardo-camera/" target="_blank" rel="noopener noreferrer"> 
                        Pedro Camera
                      </a>&ensp;:&ensp;
                      <a href="https://api.whatsapp.com/send?phone=5554935051900 " target="_blank" rel="noopener noreferrer"> 
                        (54) 93505-1900
                      </a>
                    </div>

                    <div class="small text-black-50">
                      <a href="https://www.linkedin.com/in/alexandre-da-silva-oliveira-43020926/" target="_blank" rel="noopener noreferrer"> 
                        Alexandre Oliveira
                      </a>&ensp;:&ensp;
                      <a href="https://api.whatsapp.com/send?phone=5554991904340" target="_blank" rel="noopener noreferrer"> 
                        (54) 99190-4340
                      </a>
                    </div>

                    <div class="small text-black-50">
                        Rafael Bavaresco Lopes
                       &ensp;:&ensp;
                      <a href="https://api.whatsapp.com/send?phone=5555999692548" target="_blank" rel="noopener noreferrer"> 
                        (55) 99969-2548
                      </a>
                    </div> */}



                      
                  </div>
                </div>
              </div>
            </div>
            
            {/* <div class="social d-flex justify-content-center">
              <a class="mx-2" href="#!"><i class="fab fa-twitter"></i></a>
              <a class="mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
              <a class="mx-2" href="#!"><i class="fab fa-github"></i></a>
            </div>
            */}
          </div>
        </section><footer class="footer bg-black small text-center text-white-50"><div class="container px-4 px-lg-5"> Pedro Camera/3TopService 2022 </div></footer><script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script><script src="js/scripts.js"></script><script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
    </>
  )
}

export default ThemeHome;
